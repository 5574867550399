import Bowser from 'bowser';
import { useCallback, useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { RootState } from '../store';
import { useAppSelector } from '../store/hooks';
import { APP_URL } from './defines';

type AnaliticsProps = {
  currentNotification: {};
  trylog: boolean;
  isValidBrowser: any;
  loginMessageModalOpen: boolean;
  loginMessageEmails: any;
  satcButtonDisabled: boolean;
};

interface LoadingState {
  pageLoaded: boolean;
  pageLoading: boolean;
}

const initialState: AnaliticsProps = {
  currentNotification: {},
  trylog: false,
  isValidBrowser: false,
  loginMessageModalOpen: false,
  loginMessageEmails: null,
  satcButtonDisabled: false,
};

export const useAnalytics = () => {
  const location = useLocation();
  const refLoadingValues = useRef<LoadingState>({ pageLoaded: false, pageLoading: false });
  const product = useAppSelector((state: RootState) => state.analytics.product);
  const breadcrumbList = useAppSelector((state: RootState) => state.analytics.breadcrumbList);
  const article = useAppSelector((state: RootState) => state.analytics.article);
  const authUser = useAppSelector((state) => state.auth);
  const univers = useAppSelector((state: RootState) => state.siteData.universInfo?.univers);
  const browser = Bowser.getParser(window.navigator.userAgent);

  const analyticsState = {
    ...initialState,
    isValidBrowser: browser.satisfies({ chrome: '>=60.0.3112', firefox: '>=43' }),
  };

  const allowCallDataLayer = useCallback(() => {
    if (refLoadingValues.current.pageLoading && !refLoadingValues.current.pageLoaded) {
      const isProduct = matchPath({ path: APP_URL.Base + '/' + APP_URL.Product, end: false }, location.pathname ?? '');
      const isProductReseau = matchPath(
        { path: APP_URL.Base + '/' + APP_URL.ProductReseau, end: false },
        location.pathname ?? ''
      );

      const isArtice = matchPath({ path: APP_URL.Base + '/' + APP_URL.Article, end: false }, location.pathname ?? '');
      const isArticeReseau = matchPath(
        { path: APP_URL.Base + '/' + APP_URL.ArticleReseau, end: false },
        location.pathname ?? ''
      );

      if (isProduct || isProductReseau) {
        return !!product;
      }

      if (isArtice || isArticeReseau) {
        return !!article; // && props.article.fetched;
      }

      return true;
    }

    return false;
  }, [location.pathname, product, article]);

  const onDataLayer = useCallback(() => {
    let taxonomy1 = '';
    let product_reference = '';
    let allowCheckArian = false;

    if (matchPath({ path: APP_URL.Base + '/' + APP_URL.HomePage, end: true }, location.pathname ?? '')) {
      taxonomy1 = 'Accueil';
    } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.Family, end: false }, location.pathname ?? '')) {
      taxonomy1 = 'Vignettes familles';
      allowCheckArian = true;
    } else if (
      matchPath({ path: APP_URL.Base + '/' + APP_URL.Product, end: false }, location.pathname ?? '') ||
      matchPath({ path: APP_URL.Base + '/' + APP_URL.ProductReseau, end: false }, location.pathname ?? '')
    ) {
      if (!product) return;
      taxonomy1 = 'Nomenclature produit';
      allowCheckArian = true;
      product_reference = product.code;
    } else if (
      matchPath({ path: APP_URL.Base + '/' + APP_URL.Article, end: false }, location.pathname ?? '') ||
      matchPath({ path: APP_URL.Base + '/' + APP_URL.ArticleReseau, end: false }, location.pathname ?? '')
    ) {
      if (!article) return;
      taxonomy1 = 'Fiche pièce détachée';
      allowCheckArian = true;
      product_reference = article.code;
    } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.ShoppingCart, end: true }, location.pathname ?? '')) {
      taxonomy1 = 'Panier';
    } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.OrderHistory, end: true }, location.pathname ?? '')) {
      taxonomy1 = 'Historique paniers';
    } else if (
      matchPath({ path: APP_URL.Base + '/' + APP_URL.WarrantyReplacement, end: true }, location.pathname ?? '')
    ) {
      taxonomy1 = 'Garantie';
    } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.WarrantyHistory, end: true }, location.pathname ?? '')) {
      taxonomy1 = 'Historique garanties';
    } else if (
      matchPath({ path: APP_URL.Base + '/' + APP_URL.Profile, end: true }, location.pathname ?? '') ||
      matchPath({ path: APP_URL.Base + '/' + APP_URL.Societe, end: true }, location.pathname ?? '') ||
      matchPath({ path: APP_URL.Base + '/' + APP_URL.Clients, end: true }, location.pathname ?? '')
    ) {
      taxonomy1 = 'Mes options';
    } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.Search, end: false }, location.pathname ?? '')) {
      taxonomy1 = 'Résultat de recherche';
    } else {
      return;
    }

    if (allowCheckArian && !breadcrumbList?.length) return;
    refLoadingValues.current.pageLoading = false;
    refLoadingValues.current.pageLoaded = true;

    let arbos: Record<string, any> = {
      '1': undefined,
      '2': undefined,
      '3': undefined,
      '4': undefined,
      '5': undefined,
      '6': undefined,
    };
    let product_taxonomys = {
      '1': undefined,
      '2': undefined,
      '3': undefined,
      '4': undefined,
      '5': undefined,
      '6': undefined,
    };
    if (allowCheckArian && breadcrumbList && breadcrumbList.length) {
      const arr = breadcrumbList.filter((element) => element.type === 'famille');
      arr.forEach((element, index) => {
        arbos[index + 1] = element.libelle;
      });
    }

    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    const finalObj = {
      event: 'datalayer-ready',
      page: {
        arbo1: arbos[1],
        arbo2: arbos[2],
        arbo3: arbos[3],
        arbo4: arbos[4],
        arbo5: arbos[5],
        arbo6: arbos[6],
        taxonomy1: taxonomy1,
        'product reference': product_reference,
        product_taxonomy1: product_taxonomys[1],
        product_taxonomy2: product_taxonomys[2],
        product_taxonomy3: product_taxonomys[3],
        product_taxonomy4: product_taxonomys[4],
        product_taxonomy5: product_taxonomys[5],
        product_taxonomy6: product_taxonomys[6],
      },
      user: {
        id: authUser?.uuid ?? '',
        company: {
          rscoc: authUser ? authUser?.societe?.raison_sociale : undefined, //'Le Froid Nivart'
          nature_code: authUser ? authUser?.societe?.nature_code : undefined, //'10',
          nature: authUser?.societe_nature_codes ? authUser?.societe_nature_codes.slug : undefined, //'installateur',
          type_code: authUser ? authUser?.societe?.type_code : undefined, //'21',
          type: authUser ? getUserTypeLabel(authUser?.societe?.type_code) : undefined, //'Plombier / Chauffagiste',
          siret: authUser ? authUser?.societe?.siret : undefined, //'4445768978'
        },
      },
    };

    window.dataLayer.push(finalObj);
  }, [location.pathname, breadcrumbList, authUser, product, article]);

  useEffect(() => {
    refLoadingValues.current.pageLoading = true;
    refLoadingValues.current.pageLoaded = false;

    const allowCall = allowCallDataLayer();
    allowCall && onDataLayer();
  }, [location.pathname, authUser.uuid, onDataLayer, allowCallDataLayer]);

  useEffect(() => {
    if (!univers?.datalayer) {
      return;
    }
    univers.datalayer.split(',').forEach((tagId) => {
      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${tagId}');`;
      script.async = true;
      document.head.appendChild(script);

      const script2 = document.createElement('script');
      script2.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
      script2.async = true;
      document.head.appendChild(script2);

      const noScript = document.createElement('noscript');
      noScript.innerHTML = `<iframe title="" src="https://www.googletagmanager.com/ns.html?id=${tagId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.insertBefore(noScript, document.body.childNodes[0]);
    });
  }, [univers]);

  return { allowCallDataLayer, onDataLayer, analyticsState };
};

const getUserTypeLabel = (type_code: any) => {
  const type_codes: Record<string, string> = {
    '0': 'None',
    '9': 'Coopérative',
    '10': 'Couvreur',
    '33': 'Distributeur Energie',
    '34': 'Economiste',
    '13': 'Electrique',
    '12': 'Electricien',
    '38': 'Frigoriste / Climatisation',
    '17': 'Généraliste',
    '27': 'Grossiste',
    '39': 'Groupement',
    '40': 'Groupement financier',
    '41': 'GSA',
    '25': 'GSB',
    '42': 'GSS',
    '48': 'Mixte',
    '49': 'Négoce',
    '20': 'Pièce détachée',
    '21': 'Plombier / Chauffagiste',
    '22': 'Promoteur privé',
    '23': 'Promoteur public',
    '24': 'Sanitaire / Chauffage',
    '50': 'Santé',
    '51': 'Syndicat',
    '52': 'Ventilation',
    '53': 'VPC / Internet',
    '1': 'Administration',
    '2': 'Architecte',
    '3': 'Armée',
    '31': 'Association',
    '4': 'Banque/Assurance',
    '26': 'Bureau de chiffrage',
    '5': 'Bureau de controle',
    '32': 'Bureau de pilotage',
    '6': "Bureau d'études",
    '28': 'Climaticien / Frigoriste',
    '7': 'CMI',
    '8': 'Commerce',
    '55': 'ENR',
    '15': 'Entreprise générale',
    '36': 'Exploitant',
    '37': 'Fédération',
    '29': 'Gaineux',
    '30': 'Génie climatique',
    '18': 'Hotellerie',
    '35': 'Inconnu',
    '43': 'Industrie',
    '45': 'Intégrateur CMIste',
    '44': 'Intégrateur',
    '46': 'Loisirs',
    '54': "Salle d'expo",
    '56': 'MSB',
    '57': 'Dépôt',
    '58': 'Magasin électrodomestique',
    '59': 'Rénovation (cuisine/bain)',
    '60': 'Quincaillerie',
    '61': 'Matériel de construction',
    '62': 'Pisciniste',
    '63': 'Entreprises de services énergétiques',
    '64': 'Studio de décoration',
    '65': 'Education',
    '66': 'Infrastructure',
    '67': 'Pure player / Marketplace',
    '71': 'Consommateur professionnel',
  };

  return type_codes[type_code] || type_codes['0'];
};

export const SupportDataLayerTypes = {
  Documents: 'documents',
  Prestations: 'prestation',
  Diagnostic: 'diagnostic',
};

export const onSupportDataLayer = (type: string, label: string) => {
  let selecteurType = 'Documentations et vidéos';
  if (type === SupportDataLayerTypes.Prestations) {
    selecteurType = 'Prestations et accessoires';
  } else if (type === SupportDataLayerTypes.Diagnostic) {
    selecteurType = 'Aide au diagnostic';
  }
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    selecteurType: selecteurType,
    selecteurName: label,
    event: 'selecteur-service',
  });
};

export const onSearchDataLayer = (searchText: string, data: any, key: string) => {
  let suggestionType = undefined;
  let suggestionName = undefined;
  switch (key) {
    case 'produits':
      suggestionType = 'Produit';
      suggestionName = data.libelle || data.code;
      break;
    case 'articles':
      suggestionType = 'Piece';
      suggestionName = data.libelle || data.code;
      break;
    case 'familles':
      suggestionType = 'Gamme';
      suggestionName = data.libelle || data.code;
      break;
  }

  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    recherche: searchText,
    suggestionType: suggestionType,
    suggestionName: suggestionName,
    event: 'recherche-sav-suggestion',
  });
};
