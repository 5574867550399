import { Table } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColumnType } from 'antd/lib/table';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetUseCasesQuery } from '../../store/article/articleApi';
import { Article } from '../../store/article/articleModels';
import { useAppSelector } from '../../store/hooks';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import { APP_URL } from '../../project/defines';
import useTranslation from '../../utils/hooks/useTranslation';
import Button from '../shared/button';
import Loader from '../shared/loader';
import Popup from '../shared/popup';
import { Text } from '../shared/text';
import { getAppUrl } from '../../project/helpers';

export const ArticleUseCaseButton: React.FC<{ article: Article; isWidget?: boolean }> = ({ article, isWidget }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen && (
        <Popup onClose={() => setIsOpen(false)} title={'article.boutons.utilisation'} fixSize="large">
          <ArticleUseCases
            id={article.uuid || ''}
            reseau_uuid={article.articleReseau?.reseau_uuid || ''}
            article={article}
          />
        </Popup>
      )}
      {isWidget ? (
        <Button
          large
          wide
          data-text-align="left"
          text="vignettesArticle.buttons.casUtilisation.libelle"
          icon="info"
          onClick={() => {
            setIsOpen(true);
          }}
        />
      ) : (
        <Button
          data-length="forcefit"
          accent
          icon="info"
          text="article.boutons.utilisation"
          textFirst={false}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      )}
    </>
  );
};

const ArticleUseCases: React.FC<{
  id: string;
  reseau_uuid: string;
  article: Article;
}> = ({ id, reseau_uuid, article }) => {
  type TableRecord = Record<string, any>;
  const { t } = useTranslation();
  const columns: ColumnType<TableRecord>[] = [
    {
      key: 'uuid',
      title: t('article.casUtilisation.marque'),
      dataIndex: 'marque_nom',
      width: 300,
    },
    {
      key: 'reference',
      title: t('article.casUtilisation.produit'),
      dataIndex: 'schema_uuid',
      width: 600,

      render: (value: string, record: Record<string, any>) => {
        return (
          <group data-wrap="no">
            <Link
              to={getAppUrl(APP_URL.ProductSchema, {
                routeParams: {
                  id: record.uuid,
                  schema_uuid: record.schema_uuid,
                },
              })}
            >
              [{record.reference}] {record.libelle}
            </Link>
          </group>
        );
      },
    },
  ];

  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';

  const { data: useCases, isSuccess } = useGetUseCasesQuery(
    { univers_uuid: univers?.uuid ?? '', lang, pays_uuid, id, reseau_uuid },
    { skip: !id || !pays_uuid || !univers?.uuid || !lang || !reseau_uuid }
  );
  const products: any[] = [],
    products_uuids: any[] = [];

  useCases?.map((item: any, index: number) => {
    if (products_uuids.indexOf(useCases[index].uuid) < 0) {
      products.push(item);
      products_uuids.push(useCases[index].uuid);
    }
  });

  return (
    <view data-scroll>
      <toolbar data-sticky="top" data-backdrop="">
        <Text dataWeight="700">
          [{article.reference}] {article.libelle}
        </Text>
      </toolbar>
      {useCases?.length ? (
        <Table
          // scroll={{ y: 650 }}
          data-table-highlight="zebra-even"
          ellipsis={true}
          bordered={true}
          columns={columns}
          tableLayout={'auto'}
          showHeader={true}
          dataSource={products}
          pagination={false}
          size={'small' as SizeType}
        />
      ) : (
        <group data-space="30" data-justify="center" data-position="center">
          {isSuccess ? <Text>article.casUtilisation.aucun</Text> : <Loader />}
        </group>
      )}
    </view>
  );
};
export default ArticleUseCases;
